/**
 * @fileoverview gRPC-Web generated client stub for company
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/company/company.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_company_company_pb from '../../protobuf/company/company_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class CompanyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.CreateCompanyRequest,
    protobuf_model_merged_pb.CompanyResponse,
    (request: protobuf_company_company_pb.CreateCompanyRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CompanyResponse.deserializeBinary
  );

  create(
    request: protobuf_company_company_pb.CreateCompanyRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CompanyResponse>;

  create(
    request: protobuf_company_company_pb.CreateCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CompanyResponse>;

  create(
    request: protobuf_company_company_pb.CreateCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorCreateBank = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/CreateBank',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.CreateCompanyBankRequest,
    protobuf_model_merged_pb.CompanyBankResponse,
    (request: protobuf_company_company_pb.CreateCompanyBankRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CompanyBankResponse.deserializeBinary
  );

  createBank(
    request: protobuf_company_company_pb.CreateCompanyBankRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CompanyBankResponse>;

  createBank(
    request: protobuf_company_company_pb.CreateCompanyBankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CompanyBankResponse>;

  createBank(
    request: protobuf_company_company_pb.CreateCompanyBankRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/CreateBank',
        request,
        metadata || {},
        this.methodDescriptorCreateBank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/CreateBank',
    request,
    metadata || {},
    this.methodDescriptorCreateBank);
  }

  methodDescriptorCreateBankAccount = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/CreateBankAccount',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.CreateCompanyBankAccountRequest,
    protobuf_model_merged_pb.CompanyBankAccountResponse,
    (request: protobuf_company_company_pb.CreateCompanyBankAccountRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CompanyBankAccountResponse.deserializeBinary
  );

  createBankAccount(
    request: protobuf_company_company_pb.CreateCompanyBankAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CompanyBankAccountResponse>;

  createBankAccount(
    request: protobuf_company_company_pb.CreateCompanyBankAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankAccountResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CompanyBankAccountResponse>;

  createBankAccount(
    request: protobuf_company_company_pb.CreateCompanyBankAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/CreateBankAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateBankAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/CreateBankAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateBankAccount);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.UpdateCompanyRequest,
    protobuf_model_merged_pb.CompanyResponse,
    (request: protobuf_company_company_pb.UpdateCompanyRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CompanyResponse.deserializeBinary
  );

  update(
    request: protobuf_company_company_pb.UpdateCompanyRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CompanyResponse>;

  update(
    request: protobuf_company_company_pb.UpdateCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CompanyResponse>;

  update(
    request: protobuf_company_company_pb.UpdateCompanyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateBank = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/UpdateBank',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.UpdateCompanyBankRequest,
    protobuf_model_merged_pb.CompanyBankResponse,
    (request: protobuf_company_company_pb.UpdateCompanyBankRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CompanyBankResponse.deserializeBinary
  );

  updateBank(
    request: protobuf_company_company_pb.UpdateCompanyBankRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CompanyBankResponse>;

  updateBank(
    request: protobuf_company_company_pb.UpdateCompanyBankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CompanyBankResponse>;

  updateBank(
    request: protobuf_company_company_pb.UpdateCompanyBankRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/UpdateBank',
        request,
        metadata || {},
        this.methodDescriptorUpdateBank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/UpdateBank',
    request,
    metadata || {},
    this.methodDescriptorUpdateBank);
  }

  methodDescriptorUpdateBankAccount = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/UpdateBankAccount',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.UpdateCompanyBankAccountRequest,
    protobuf_model_merged_pb.CompanyBankAccountResponse,
    (request: protobuf_company_company_pb.UpdateCompanyBankAccountRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CompanyBankAccountResponse.deserializeBinary
  );

  updateBankAccount(
    request: protobuf_company_company_pb.UpdateCompanyBankAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CompanyBankAccountResponse>;

  updateBankAccount(
    request: protobuf_company_company_pb.UpdateCompanyBankAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankAccountResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CompanyBankAccountResponse>;

  updateBankAccount(
    request: protobuf_company_company_pb.UpdateCompanyBankAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CompanyBankAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/UpdateBankAccount',
        request,
        metadata || {},
        this.methodDescriptorUpdateBankAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/UpdateBankAccount',
    request,
    metadata || {},
    this.methodDescriptorUpdateBankAccount);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.GetCompaniesRequest,
    protobuf_company_company_pb.GetCompaniesResponse,
    (request: protobuf_company_company_pb.GetCompaniesRequest) => {
      return request.serializeBinary();
    },
    protobuf_company_company_pb.GetCompaniesResponse.deserializeBinary
  );

  get(
    request: protobuf_company_company_pb.GetCompaniesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_company_company_pb.GetCompaniesResponse>;

  get(
    request: protobuf_company_company_pb.GetCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.GetCompaniesResponse) => void): grpcWeb.ClientReadableStream<protobuf_company_company_pb.GetCompaniesResponse>;

  get(
    request: protobuf_company_company_pb.GetCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.GetCompaniesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.DeleteCompaniesRequest,
    protobuf_company_company_pb.DeleteCompaniesResponse,
    (request: protobuf_company_company_pb.DeleteCompaniesRequest) => {
      return request.serializeBinary();
    },
    protobuf_company_company_pb.DeleteCompaniesResponse.deserializeBinary
  );

  delete(
    request: protobuf_company_company_pb.DeleteCompaniesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_company_company_pb.DeleteCompaniesResponse>;

  delete(
    request: protobuf_company_company_pb.DeleteCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.DeleteCompaniesResponse) => void): grpcWeb.ClientReadableStream<protobuf_company_company_pb.DeleteCompaniesResponse>;

  delete(
    request: protobuf_company_company_pb.DeleteCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.DeleteCompaniesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorDeleteBank = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/DeleteBank',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.DeleteCompanyBanksRequest,
    protobuf_company_company_pb.DeleteCompanyBanksResponse,
    (request: protobuf_company_company_pb.DeleteCompanyBanksRequest) => {
      return request.serializeBinary();
    },
    protobuf_company_company_pb.DeleteCompanyBanksResponse.deserializeBinary
  );

  deleteBank(
    request: protobuf_company_company_pb.DeleteCompanyBanksRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_company_company_pb.DeleteCompanyBanksResponse>;

  deleteBank(
    request: protobuf_company_company_pb.DeleteCompanyBanksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.DeleteCompanyBanksResponse) => void): grpcWeb.ClientReadableStream<protobuf_company_company_pb.DeleteCompanyBanksResponse>;

  deleteBank(
    request: protobuf_company_company_pb.DeleteCompanyBanksRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.DeleteCompanyBanksResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/DeleteBank',
        request,
        metadata || {},
        this.methodDescriptorDeleteBank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/DeleteBank',
    request,
    metadata || {},
    this.methodDescriptorDeleteBank);
  }

  methodDescriptorDeleteBankAccount = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/DeleteBankAccount',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.DeleteCompanyBankAccountsRequest,
    protobuf_company_company_pb.DeleteCompanyBankAccountsResponse,
    (request: protobuf_company_company_pb.DeleteCompanyBankAccountsRequest) => {
      return request.serializeBinary();
    },
    protobuf_company_company_pb.DeleteCompanyBankAccountsResponse.deserializeBinary
  );

  deleteBankAccount(
    request: protobuf_company_company_pb.DeleteCompanyBankAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_company_company_pb.DeleteCompanyBankAccountsResponse>;

  deleteBankAccount(
    request: protobuf_company_company_pb.DeleteCompanyBankAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.DeleteCompanyBankAccountsResponse) => void): grpcWeb.ClientReadableStream<protobuf_company_company_pb.DeleteCompanyBankAccountsResponse>;

  deleteBankAccount(
    request: protobuf_company_company_pb.DeleteCompanyBankAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.DeleteCompanyBankAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/DeleteBankAccount',
        request,
        metadata || {},
        this.methodDescriptorDeleteBankAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/DeleteBankAccount',
    request,
    metadata || {},
    this.methodDescriptorDeleteBankAccount);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.ListCompaniesRequest,
    protobuf_company_company_pb.ListCompaniesResponse,
    (request: protobuf_company_company_pb.ListCompaniesRequest) => {
      return request.serializeBinary();
    },
    protobuf_company_company_pb.ListCompaniesResponse.deserializeBinary
  );

  list(
    request: protobuf_company_company_pb.ListCompaniesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_company_company_pb.ListCompaniesResponse>;

  list(
    request: protobuf_company_company_pb.ListCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.ListCompaniesResponse) => void): grpcWeb.ClientReadableStream<protobuf_company_company_pb.ListCompaniesResponse>;

  list(
    request: protobuf_company_company_pb.ListCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.ListCompaniesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/company.CompanyService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_company_company_pb.ExportCompaniesRequest,
    protobuf_company_company_pb.ExportCompaniesResponse,
    (request: protobuf_company_company_pb.ExportCompaniesRequest) => {
      return request.serializeBinary();
    },
    protobuf_company_company_pb.ExportCompaniesResponse.deserializeBinary
  );

  export(
    request: protobuf_company_company_pb.ExportCompaniesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_company_company_pb.ExportCompaniesResponse>;

  export(
    request: protobuf_company_company_pb.ExportCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.ExportCompaniesResponse) => void): grpcWeb.ClientReadableStream<protobuf_company_company_pb.ExportCompaniesResponse>;

  export(
    request: protobuf_company_company_pb.ExportCompaniesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_company_company_pb.ExportCompaniesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/company.CompanyService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/company.CompanyService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

